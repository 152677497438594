export function shortDate(date: Date) {
  // According to MDN dateStyle is supported by IE11.
  const options = { dateStyle: 'short' } as Intl.DateTimeFormatOptions;
  return date.toLocaleString(undefined, options);
}

export function ymdToDate(startDate: string): Date {
  const [y, m, d] = startDate.split('-');
  return new Date(parseFloat(y), parseFloat(m) - 1, parseFloat(d));
}

function padNumberWithZero(n: number): string {
  return n < 10 ? '0' + n : '' + n;
}

export function dateToYMD(date: Date): string {
  return [date.getFullYear(), padNumberWithZero(date.getMonth() + 1), padNumberWithZero(date.getDate())].join('-');
}
