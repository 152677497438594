import { upgradeReferralClick } from '@wix/bi-logger-membership/v2';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { AppProps, MessageCode } from '../../../types/common';
import { errorToMessage, toError } from '../../../utils/errors';
import { CheckoutController } from './CheckoutController';
import { ListController } from './ListController';
import { RestrictedController } from './RestrictedController';
import { Router } from './Router';
import { StatusController } from './StatusController';

export class App {
  constructor(
    public setProps: (props: Partial<AppProps>) => void,
    protected list: ListController,
    protected checkout: CheckoutController,
    protected status: StatusController,
    protected restricted: RestrictedController,
    protected router: Router,
    protected flowAPI: ControllerFlowAPI,
  ) {}

  public async initialize() {
    this.setProps({
      hideToast: this.hideToast,
      showToast: this.showToast,
      biUpgradeReferralClick: (referralInfo: string) =>
        this.flowAPI.bi?.report(upgradeReferralClick({ referralInfo, isPreview: false })),
    });

    this.router.whenInit({
      list: (integrationData) => this.list.initialize(integrationData),
      checkout: (checkoutData) => this.checkout.initialize(checkoutData),
      status: (statusData) => this.status.initialize(statusData),
      restricted: ({ pageId, integrationData }) => this.restricted.initialize(pageId, integrationData),
    });

    this.router.whenNavigate({
      list: ({ integrationData, message }) => {
        this.setProps({ message });
        return this.list.update(integrationData);
      },
      checkout: async ({ integrationData, plan, order }) => {
        this.setProps({ selectedPlan: plan });
        return this.checkout.update(plan, integrationData, order);
      },
      status: ({ statusData, plan }) => this.status.update(statusData, plan),
      restricted: ({ pageId, integrationData }) => this.restricted.update(pageId, integrationData),
    });

    try {
      await this.router.initialize();
    } catch (e) {
      this.flowAPI.reportError(toError(e));
      await this.list.initialize({});
      this.setProps({ message: errorToMessage(toError(e)) });
    }
  }

  hideToast = () => this.setProps({ message: undefined });
  showToast = (message: MessageCode) => this.setProps({ message });
}
