import { Order, SpannedPrice } from '@wix/ambassador-pricing-plans-v2-order/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';

export const hasOrderCoupon = (order: Order) => {
  return Boolean(order?.pricing?.prices?.[0]?.price?.coupon);
};

export const getOrderCoupon = (order: Order) => {
  return getPricesCoupon(order?.pricing?.prices);
};

export const getPricesCoupon = (prices?: SpannedPrice[]) => {
  return prices?.[0]?.price?.coupon;
};

export const isOneTimePlan = (plan: PublicPlan) => {
  return plan?.pricing?.singlePaymentUnlimited !== undefined || plan?.pricing?.singlePaymentForDuration !== undefined;
};

export const hasZeroPrice = (prices?: SpannedPrice[]) => {
  if (prices) {
    return prices.every((spannedPrice) => spannedPrice.price?.total === '0');
  }
  return false;
};
