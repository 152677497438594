import { decodeBase64, encodeBase64 } from '../services/encode-base64';
import { IntegrationData } from '../types/common';
import { IntegrationDataDecodeError, toError } from './errors';

export function integrationDataToAppSectionParams(integrationData: IntegrationData) {
  const {
    planIds,
    navigateToPageProps,
    navigateToSectionProps,
    biOptions,
    verticalStatusContent,
    ...rest
  } = integrationData;
  const params = new URLSearchParams({
    appSectionParams: JSON.stringify({
      ...rest,
      planIds: planIds?.join(','),
      biOptions: biOptions && encodeBase64(biOptions),
      navigateToSectionProps: navigateToSectionProps && encodeBase64(navigateToSectionProps),
      navigateToPageProps: navigateToPageProps && window.btoa(navigateToPageProps),
      verticalStatusContent: verticalStatusContent && encodeBase64(verticalStatusContent),
    }),
  });
  return params.toString();
}

export function extractIntegrationData(appSectionParams: Record<string, string>): IntegrationData {
  try {
    const {
      planIds,
      navigateToSectionProps,
      navigateToPageProps,
      biOptions,
      verticalStatusContent,
      ...rest
    } = appSectionParams;
    return {
      ...rest,
      navigateToPageProps: navigateToPageProps && window.atob(navigateToPageProps),
      navigateToSectionProps: navigateToSectionProps && decodeBase64(navigateToSectionProps),
      verticalStatusContent: verticalStatusContent && decodeBase64(verticalStatusContent),
      biOptions: biOptions ? decodeBase64(biOptions) : undefined,
      planIds: planIds ? planIds.split(',') : undefined,
    };
  } catch (e) {
    throw new IntegrationDataDecodeError(toError(e).message);
  }
}
