import { list } from '@wix/ambassador-cashier-pay-v2-payment-method/http';
import { ChargeIntent } from '@wix/ambassador-cashier-pay-v2-payment-method/types';
import { IHttpClient } from '@wix/yoshi-flow-editor';
import applicationConfig from '../../.application.json';

export class PaymentMethodsApi {
  constructor(private httpClient: IHttpClient, private instanceId: string) {}

  public async getPaymentMethods(chargeIntent: ChargeIntent) {
    const response = await this.httpClient.request(
      list({
        accountId: `${applicationConfig.appDefinitionId}:${this.instanceId}`,
        chargeIntent,
      }),
    );

    return response.data.paymentMethods;
  }
}

export function createPaymentMethodsApi(httpClient: IHttpClient, instanceId: string) {
  return new PaymentMethodsApi(httpClient, instanceId);
}
